body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position:relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


nav {
  background: #2f588e;
  color: white;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.blue-banner {
  display: flex;
  flex-wrap: wrap;
  background-color: #173457;
}

.dropdown {
  float: left;
  overflow: hidden;
  border-left-style: solid;
  border-right-style: none;
  border-color: #ffffff;
  border-width: 1px;
}

.dropdown .dropbtn {
  font-size: 20px;  
  border: none;
  outline: none;
  color: white;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000000;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.dropdown-content li {
  float: none;
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.banner {
  padding-left: 5px;
  padding-right: 5px;
  display: block;
  margin: 0px auto;
  margin-bottom: -80px;
  margin-top: 30px;
  
  width: fit-content;
  max-width: max-content;
  text-align: center; 
  font-size: 32px;
  font-weight: bold; 
  color: white;
}

.dropdown-item {
  color: #173457;
}

p, ul, li{
  text-align: center;
  font-size: 21px;
}

address {
  display: inline;
  text-decoration: none;
  margin-right: 10px;
}

.mx-auto {
  margin-top: 30px
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.right-image {
  display: block;
  float:right;
}

.left-image {
  display: block;
  float:left;
}

.home-page-button {
  padding: 5px;
  text-align: center;
  padding-top: 10px;
}

.home-page-button:hover {
  background-color:#2f588e;
  border-radius: 10px;
}

.home-page-images {
  text-align: center;
  padding-top: 10px;
}

.headtext {
  display: block;
   margin-left: 125px; 
   margin-top: -100px; 
  font-size: 26px;
}


.info {
  float: right;
  margin-top: 20px;
  margin-right: 10px;
  font-size: 18px;
}

footer {
  margin-top: auto;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
  display: block;
  text-align: center;
  margin-top: 60px;
  background-color: #173457;
  color: white;
  font-size: large;
}

a {
  text-decoration: none;
  color: #ffffff;
}

.colored-link {
  color: #173457;
}

.colored-link:hover {
  text-decoration: underline;
}

header a:hover, footer a:hover {
  text-decoration: underline;
}

#imgContainer {
  margin-top: 8em;
  margin-bottom: 8em;
}

.mx-auto {
  margin-top: 30px
}

.header-text-desktop {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  font-size: xxx-large;
}

.header-text-mobile {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  font-size: xx-large;
}

.contact {
  transition: 0.5s;
  padding: 10px;
  background-color: #173457;
  color: white;
  font-size: 30px;
  border-radius: 8px;
  border-color: black;
}

.contact-center {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
}
  
  .contact:hover {
  transition: 0.5s;
  padding: 10px;
  background-color: #41628d;
  cursor: pointer;
  border-color: rgb(31, 30, 30)
  }

.table-border {
  border: 1px solid black;
}