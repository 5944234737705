
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.business-button {
  transition: 0.5s;
  padding: 10px;
  background-color: #173457;
  color: white;
  font-size: 30px;
  border-radius: 8px;
  border-color: black;
}

.button-container {
  padding: 20px;
}

.loan-button-align {
  float: right;
  padding: 20px;
}

.funding-button-align {
  float: left;
  padding: 20px;
}
  
  .business-button:hover {
  transition: 0.5s;
  padding: 10px;
  background-color: #41628d;
  cursor: pointer;
  border-color: rgb(31, 30, 30)
  }


/* Loan calculator styles */

.calculate {
transition: 0.5s;
padding: 10px;
background-color: #173457;
color: white;
}

.calculate:hover {
transition: 0.5s;
padding: 10px;
background-color: #41628d;
cursor: pointer;
}

#calculation
{
  font-size: large;
}

.slider {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}

/*end of Loan calculator styles */

.width-80 {
  width: 80%;
  margin: auto;
}

.removeLinkDefault {
  text-decoration: none;
  color: white;
}

.removeLinkDefault:hover {
  transition: 0.5s;
  background-color: #41628d;
  cursor: pointer;
  text-decoration: none;
}

.center {
 display: flex;
 justify-content: center;
 gap: 30px;
}

.center-img {
  display: block;
  margin: auto;
}

.align-text-left {
  text-align: left;
}

caption {
  font-weight: boldf;
}

.side-by-side ul {
 display: inline-block; 
}

.slogan {
  font-size: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.navbar-brand {
  display: block;
}

/*Beginning of Funding Lookup Table Styles*/
.use-of-proceeds ul li {
  text-align: left;
  list-style-type: none;
  margin-left: -40px;
  font-size: 18px;
}

.search-output-display {
  list-style-type: none;
  font-size: 18px;
}

.search-button {
  transition: 0.5s;
  padding: 10px;
  background-color: #173457;
  color: white;
  font-size: 30px;
  border-radius: 8px;
  border-color: black;
}

.search-button:hover {
  transition: 0.5s;
  padding: 10px;
  background-color: #41628d;
  cursor: pointer;
  border-color: rgb(31, 30, 30)
  }

/*End of Funding Lookup Table Styles*/

@media screen and (max-width: 1366px) {
  body p {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  body p {
    font-size: 20px;
  }

  .banner {
    font-size: 25px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

}

@media screen and (max-width: 768px) {
  body p {
    font-size: 20px;
  }

  .banner {
    font-size: 25px;
  }

}

@media screen and (max-width: 480px) {
  
  body p {
    font-size: 15px;
  }

  .headtext {
    font-size: 23px;
    margin-top: -80px; 
  }

  footer {
    position: fixed;
  }

  .content-image {  
    width: 50vw;
    height: 50vw;
  }

   .info {
    font-size: 18px;
    display:block;
  } 

  li {
    font-size: 15px;
  }

  .LEANOfficeWords {
    width: 320px;
  }

}

@media screen and (max-width: 400px) {
  .headtext {
    font-size: 15px;
  }

  .banner {
    font-size: 22px;
  }

  .navbar-brand {
    margin-bottom: 20px;
  }


  .content-image {  
    width: 40vw;
    height: 40vw;
  }

   .info {
    font-size: 15px;
    display:block;
  } 

  .LEANOfficeWords {
    width: 320px;
  }
}